import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './styles/CustomScrollbar.css';
import Navbar from './components/Navbar/Navbar';
import HeroSection from './sections/Hero/HeroSection';
import Middle from './sections/Middle/Middle';
import ServicesAndApproach from './sections/SandP/ServicesAndApproach';
import TechnologyAndAboutUs from './sections/TandA/TechnologyAndAboutUs';
import Ending from './sections/Ending/Ending';
import Footer from './components/Footer/Footer';
import MobileNavbar from './components/Navbar/MobileNavbar';
import PrivacyPolicy from './pages/PrivacyPolicyPage';
import { ScrollToTop } from './components/ScrollToTop';

function App() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const links = [
        { href: "#our-services", label: "Our services" },
        { href: "#our-approach", label: "Our approach" },
        { href: "#technology", label: "Technology" },
        { href: "#about-us", label: "About us" },
    ];

    return (
        <Router>
            <ScrollToTop />
            <Navbar onToggleMenu={toggleMenu} links={links} />
            
            <Routes>
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/" element={
                    <>
                        <HeroSection />
                        <ServicesAndApproach />
                        <Middle />
                        <TechnologyAndAboutUs />
                        <Ending />
                    </>
                } />
            </Routes>

            <Footer />
            <MobileNavbar links={links} onClose={closeMenu} isOpen={menuOpen} />
        </Router>
    );
}

export default App;
