import React from 'react';
import LogoWithSlogan from './LogoWithSlogan/LogoWithSlogan';
import MainButton from '../MainButton';
import imenu from '../../assets/icons/common/hamburger.svg';

const Navbar = ({ onToggleMenu, links }) => {

    return (
        <nav className="fixed z-50 top-0 left-0 w-full h-[75px] border-b border-gray-300 bg-[rgba(255,255,255,0.20)] backdrop-blur-[7px] px-6 lg:px-[150px] xl:px-[200px] flex items-center">
            <LogoWithSlogan />

            <div className="flex-grow"></div>

            <div className="lg:hidden">
                <MainButton text="Contact us" showIcon={false} scrollToId="contact-us" />
            </div>

            <button
                className="lg:hidden flex items-center justify-center bg-[rgba(255,255,255,0.20)] border-stroke-2 border backdrop-blur-[3px] rounded-full p-3 ml-4"
                onClick={onToggleMenu}
            >
                <img src={imenu} alt="Menu" className="w-6 h-6" />
            </button>

            <ul className="hidden lg:flex items-center space-x-6 xl:space-x-8 text-sm font-[400] text-black font-inter">
                {links.map((link) => (
                    <li key={link.label}>
                        <a href={link.href} className="text-gray-700 hover:text-gray-900 whitespace-nowrap">
                            {link.label}
                        </a>
                    </li>
                ))}
                <MainButton text="Contact us" showIcon={false} scrollToId="contact-us" />
            </ul>
        </nav>
    );
};

export default Navbar;
